<script>
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import debounce from 'lodash/debounce';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'filter-map',

  data (){
    return {
      mapa: 'leaflet-map',
      loadingFarms: false,
      loadingModules: false,
      form: { label: true },
      farms: [],
      modulesRaw: [],
      modules: [],
      dates: [],
      types: ['Imagem Satélite','Matéria seca', 'Fibra', 'Energia', 'Proteína']
    }
  },

  computed: {
    ...mapState('dashboard', ['filter']),
    ...mapGetters('auth', ['authStatus', 'user'])
  },

  watch: {
    imediate: true,
    'form.module_id'(val) {
      const module = this.modulesRaw.find(item => item.id === val)
      if (!module) return
      this.paddocks = module.paddocks.map(item => ({ text: item.code, value: item.id }))
    },

    filter() {
      if (this.filter.paddockIds !== this.form.paddockIds) {
        this.form.paddockIds = this.filter.paddockIds
        this.$forceUpdate();
      }
    }
  },

  async mounted() {
    this.changeFilter = debounce(this.storeChangeFilter, 500)
    this.changeMeasureDateFilter = debounce(this.changeMeasureDateFilter, 500)
    this.changeMeasureTypeFilter = debounce(this.changeMeasureTypeFilter, 500)
    await this.fetchFarms()
    await this.fetchModules()
    this.form.moduleId = this.modules[0]
    await this.fetchMeasureDates()
    this.form.date = this.dates[0]
    this.form.type = this.types[1]
    this.form.map = true
    this.changeFilter()
  },

  methods:{
    storeChangeFilter() {
      this.$store.dispatch('dashboard/changeImageFilter', this.form)
    },

    changeMeasureDateFilter() {
      this.$store.dispatch('dashboard/changeMeasureDateFilter', this.form)
    },

    changeMeasureTypeFilter() {
      this.$store.dispatch('dashboard/changeMeasureTypeFilter', this.form)
    },

    async fetchFarms() {
      this.loadingFarms = true
      try {
        let result
        if (this.user.role === 'manager') {
          result = await this.$apollo.query({
            query: gql`
              query farms {
                farms(
                  where: {
                    desactivated: {_is_null: true}
                  },
                  order_by: [{ name: asc }]
                ) {
                  id
                  name
                  desactivated
                  customer{
                    id
                    name
                  }
                }
              }
            `,
            fetchPolicy: 'no-cache',
          });
          let desactivatedFarms = await this.$apollo.query({
            query: gql`
              query farms {
                farms(
                  where: {
                    desactivated: {_eq: true}
                  },
                  order_by: [{ name: asc }]
                ) {
                  id
                  name
                  desactivated
                  customer{
                    id
                    name
                  }
                }
              }
            `,
            fetchPolicy: 'no-cache',
          });
          if (desactivatedFarms && desactivatedFarms.data && desactivatedFarms.data.farms) {
            result.data.farms = [...result.data.farms, ...desactivatedFarms.data.farms]
          }
        } else {
          result = await this.$apollo.query({
            query: gql`
              query farms {
                farms(
                  order_by: [{ name: asc }]
                ) {
                  id
                  name
                  customer{
                    id
                    name
                  }
                }
              }
            `,
            fetchPolicy: 'no-cache',
          });
        }
        this.loadingFarms = false
        this.farms = result.data.farms.map(item => ({value: item.id, text: item.name, ...item}));
        let storage = localStorage.getItem('lastFarm')
        if (storage) {
          this.form.farmId = storage
        } else if (this.farms.length > 0) {
          this.form.farmId = this.farms[0].id
        }
      } catch (e) {
        console.error(e);
      }
      this.loadingFarms = false
    },

    async fetchMeasureDates () {
      this.dates = []
      if (this.form.farmId) {
        let measureDates = await this.$apollo.query({
          query: gql`
            query satellites_data($farm_id: uuid! ) {
              satellites_data(
                where: {  
                  farm_id: {_eq: $farm_id},
                  type: { _eq: OPTICO },
                  deleted_at: { _is_null: true },
                }, order_by: { captured_at: desc },
                distinct_on: captured_at
              ) {
                captured_at
                }
            }
          `,
          variables: {
            farm_id: this.form.farmId,
          },
          fetchPolicy: 'no-cache',
        });
        if (measureDates && measureDates.data && measureDates.data.satellites_data) {
          let dates = measureDates.data.satellites_data
          for (let index = 0; index < dates.length; index++){
            this.dates.push(dayjs(dates[index].captured_at).format('DD-MM-YYYY'))
          }
          this.form.date = this.dates[0]
          this.form.type = this.types[1]
        }
      }
    },

    farmFilter (item, queryText) {
      const textOne = item?.name?.toLowerCase()
      const textTwo = item?.customer?.name?.toLowerCase()
      const searchText = queryText.toLowerCase()

      // Validação de texto
      if (!textOne || !textTwo) return false

      // Verifica se contém o texto
      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    },

    handleChangeFarm() {
      this.form.moduleId = null
      this.form.paddockIds = []
      localStorage.setItem('lastFarm', this.form.farmId)
      this.changeFilter()
      this.fetchModules()
      this.fetchMeasureDates()
      this.form.date = this.dates[0]
      this.changeMeasureDateFilter()
      this.changeMeasureTypeFilter()
    },
    
    handleChangeModule() {
      localStorage.setItem('lastModule', this.form.moduleId)
      this.changeFilter()
    },

    async fetchModules(){
      this.loadingModules = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query modules ($farmId: uuid!) {
              modules(
                where: {
                  farm_id: { _eq: $farmId },
                  temporary: { _eq: false }
                },
                order_by: [{ name: asc }]
              ) {
                id
                name
                paddocks{
                  id
                  name
                  code
                }
              }
            }
          `,
          variables: {
            farmId: this.form.farmId
          },
          fetchPolicy: 'no-cache',
        });
        this.loadingModules = false
        this.modulesRaw = result.data.modules;
        this.modules = result.data.modules.map(item => ({value: item.id, text: item.name}));
        this.modules.unshift('Todos')
      } catch (e) {
        console.error(e);
      }
      this.loadingModules = false
    },

  }
}
</script>

<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            v-model="form.farmId"
            :items="farms"
            :loading="loadingFarms"
            :filter="farmFilter"
            label="Fazenda"
            dense
            outlined
            hide-details
            persistent-placeholder
            @input="handleChangeFarm"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-if="!data.item.desactivated" v-html="data.item.name"></v-list-item-title>
                <v-list-item-title v-if="data.item.desactivated" v-html="data.item.name + ' (inativa)'"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.customer.name"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <v-autocomplete
            v-model="form.moduleId"
            :items="modules"
            :loading="loadingModules"
            label="Módulo"
            placeholder="Todos"
            dense
            outlined
            hide-details
            persistent-placeholder
            @change="handleChangeModule"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="form.date"
            :items="dates"
            label="Data da Medição"
            class="text-field-right"
            dense
            outlined
            hide-details
            persistent-placeholder
            @input="changeMeasureDateFilter"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="form.type"
            :items="types"
            label="Parâmetros"
            class="text-field-right"
            dense
            outlined
            hide-details
            persistent-placeholder
            @input="changeMeasureTypeFilter"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-checkbox
            v-model="form.label"
            label="Nome Piquete"
            class="text-field-right"
            dense
            outlined
            hide-details
            persistent-placeholder
            @click="changeMeasureTypeFilter"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
